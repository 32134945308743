.cart-page {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  margin: auto;
}

.order-details {
  margin-top: 20px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
}

.detail-row span {
  color: #5a5a5b;
}

.points {
  font-weight: bold;
}

.remaining-pts {
  font-weight: bold;
  color: #00db8f !important;
}

hr {
  border: none;
  height: 1px;
  background-color: #ececec;
  margin: 25px 0;
}
