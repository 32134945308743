@font-face {
  font-family: "AvenirNext-Bold";
  src: url("../public/fonts/AvenirNext-Bold.eot");
  src: url("../public/fonts/AvenirNext-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-Bold.woff") format("woff"),
    url("../public/fonts/AvenirNext-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-BoldItalic";
  src: url("../public/fonts/AvenirNext-BoldItalic.eot");
  src: url("../public/fonts/AvenirNext-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-BoldItalic.woff") format("woff"),
    url("../public/fonts/AvenirNext-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url("../public/fonts/AvenirNext-DemiBold.eot");
  src: url("../public/fonts/AvenirNext-DemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-DemiBold.woff") format("woff"),
    url("../public/fonts/AvenirNext-DemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-DemiBoldItalic";
  src: url("../public/fonts/AvenirNext-DemiBoldItalic.eot");
  src: url("../public/fonts/AvenirNext-DemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-DemiBoldItalic.woff") format("woff"),
    url("../public/fonts/AvenirNext-DemiBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Heavy";
  src: url("../public/fonts/AvenirNext-Heavy.eot");
  src: url("../public/fonts/AvenirNext-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-Heavy.woff") format("woff"),
    url("../public/fonts/AvenirNext-Heavy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-HeavyItalic";
  src: url("../public/fonts/AvenirNext-HeavyItalic.eot");
  src: url("../public/fonts/AvenirNext-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-HeavyItalic.woff") format("woff"),
    url("../public/fonts/AvenirNext-HeavyItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Italic";
  src: url("../public/fonts/AvenirNext-Italic.eot");
  src: url("../public/fonts/AvenirNext-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-Italic.woff") format("woff"),
    url("../public/fonts/AvenirNext-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Medium";
  src: url("../public/fonts/AvenirNext-Medium.eot");
  src: url("../public/fonts/AvenirNext-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-Medium.woff") format("woff"),
    url("../public/fonts/AvenirNext-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-MediumItalic";
  src: url("../public/fonts/AvenirNext-MediumItalic.eot");
  src: url("../public/fonts/AvenirNext-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-MediumItalic.woff") format("woff"),
    url("../public/fonts/AvenirNext-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-Regular";
  src: url("../public/fonts/AvenirNext-Regular.eot");
  src: url("../public/fonts/AvenirNext-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-Regular.woff") format("woff"),
    url("../public/fonts/AvenirNext-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-UltraLight";
  src: url("../public/fonts/AvenirNext-UltraLight.eot");
  src: url("../public/fonts/AvenirNext-UltraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-UltraLight.woff") format("woff"),
    url("../public/fonts/AvenirNext-UltraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext-UltraLightItalic";
  src: url("../public/fonts/AvenirNext-UltraLightItalic.eot");
  src: url("../public/fonts/AvenirNext-UltraLightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/AvenirNext-UltraLightItalic.woff") format("woff"),
    url("../public/fonts/AvenirNext-UltraLightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "AvenirNext", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
