.loan-agreement {
  border: 0.5px solid lightgray;
  height: 320px;
  overflow: scroll;
  width: 100% !important;
  font-size: 9pt;
  font-family: "Arial";
}

.pd-10 {
  padding: 10px;
}
